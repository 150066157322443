import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private timer: any;
  private timeoutPeriod = 60000; 

  constructor(private router: Router, private ngZone: NgZone) {
    this.resetTimer();
    this.setupListeners();
  }

  setupListeners() {
    ['mousemove', 'click', 'keydown', 'scroll'].forEach(event => {
      window.addEventListener(event, () => this.resetTimer());
    });
  }

  resetTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => this.redirectToLockScreen(), this.timeoutPeriod);
  }

  redirectToLockScreen() {
    this.ngZone.run(() => {
      this.router.navigate(['auth/lock-screen']);
    });
  }
}
