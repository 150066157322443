import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NzIconService } from 'ng-zorro-antd/icon';
import {
  CHAMPAGNE_SVG,
  DISH_SVG,
  DRINK_SVG,
  FRENCH_FRIES_SVG,
  PIZZA_SVG,
  RECEIPT_SVG,
  WATER_SVG,
  WINE_SVG,
} from './shared/consts/svgs-icons';
import { InactivityService } from './core/services/inactivity.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  constructor(private iconService: NzIconService,
    private inactivityService: InactivityService
  ) {
    this.iconService.addIconLiteral('menuize:receipt', RECEIPT_SVG);
    this.iconService.addIconLiteral('menuize:dish', DISH_SVG);
    this.iconService.addIconLiteral('menuize:french-fries', FRENCH_FRIES_SVG);
    this.iconService.addIconLiteral('menuize:pizza', PIZZA_SVG);
    this.iconService.addIconLiteral('menuize:water', WATER_SVG);
    this.iconService.addIconLiteral('menuize:drink', DRINK_SVG);
    this.iconService.addIconLiteral('menuize:wine', WINE_SVG);
    this.iconService.addIconLiteral('menuize:champagne', CHAMPAGNE_SVG);
  }
}
